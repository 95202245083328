body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",*/
    /*"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",*/
    /*sans-serif;*/
  font-family: "Poppins";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.searchbox{
  border-radius: 34px;
  height: 40px;
  width: 80%;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
  border: solid 1px #0011FF;
}
.searchbox:focus{
  outline: none;
}
#root > div > div.Connect\(MainBoard\)-phonebackground-3 > div > div:nth-child(2) > div > div:nth-child(4) > div:nth-child(3) > div > p{
  display: none;
}
#root > div > div.Connect\(MainBoard\)-phonebackground-3 > div > div:nth-child(2) > div > div:nth-child(2) > div:nth-child(2) > div:nth-child(2) > div:nth-child(1) > div > input{
  padding: 0;
}
#root > div > div.Connect\(MainBoard\)-phonebackground-3 > div > div:nth-child(2) > div > div:nth-child(2) > div:nth-child(2) > div:nth-child(2) > div:nth-child(1) > div > div > input{
  padding: 0;
  text-align: center;
  background-color: white;
}
#root > div > div.Connect\(MainBoard\)-phonebackground-3 > div > div:nth-child(2) > div > div:nth-child(2) > div:nth-child(2) > div:nth-child(2) > div:nth-child(1) > div{
  margin: 0;
}
.MuiInputBase-input.MuiFilledInput-input{
  padding: 0;
}
.MuiFormControl-marginNormal{
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
